import React, { useState } from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';

const PictureUpload = ({ open, onClose, onSubmit }) => {
  const [preview, setPreview] = useState(null);
  const [base64Data, setBase64Data] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // base64 string
        const _base64Data = reader.result.split(',')[1];
        setBase64Data(_base64Data)
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Upload a New Picture</Modal.Header>
      <Modal.Content>
        <input type="file" onChange={handleFileChange} />
        {preview && (
          <Image src={preview} size="medium" centered style={{ marginTop: '20px' }} />
        )}
        <div style={{marginTop:'5px'}}>Max resolution 200x200</div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Discard</Button>
        <Button
          onClick={() => {
            onSubmit(base64Data);
          }}
          primary
          disabled={!preview}
        >
          Accept
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PictureUpload;
