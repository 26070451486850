import { apiServer } from '../settings';
import { getToken } from './token';
// import createLog from '../utils/create-log';

const setPicture = (userGuid, userAuthorization, game, guid, base64, done) => {
    // if (!userGuid) return done('missing userGuid')
    // if (!userAuthorization) return done('missing userAuthorization')
    if (!game) return done('missing game');
    if (!guid) return done('missing guid');
    if (!base64) return done('missing base64');

    const headers = new Headers({
        Authorization: 'Bearer ' + getToken(),
        'Content-Type': 'application/json',
    });

    const options = {
        method: 'PUT',
        headers,
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify({ base64 }),  // Make sure base64 is not too large!
    };

    const url = `${apiServer}/user-set-picture?game=${game}&guid=${guid}`;

    fetch(url, options)
        .then(response => {
            // Handle 413
            if (response.status === 413) {
                throw new Error('Payload Too Large (413)');
            }
            
            // If not 413, attempt to parse JSON
            return response.json();
        })
        .then(response => {
            if (response.success === false) return done(response.err);
            if (response.success === true) return done();
            if (!response.success) return done('response without success true|false');
        })
        .catch(error => {
            console.error('Error:', error);
            done(error.message);
        });
};

export default setPicture;
