import React, { useState } from 'react'

import { Header, Table, Button, Modal, Grid, Segment } from 'semantic-ui-react'

// Modale che mostra la lista dei players con i loro contributi
const PlayersModal = ({ total, players }) => {
    const [isOpen, setOpen] = useState(false)

    const WhatToRender = ({ player }) => {
        // is score
        if (player.value)
            return (
                <>
                    <b>{player.guid}</b> {player.value}
                </>
            )
        // Is counter
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Segment>
                            <Header
                                as="h4"
                                style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ width: '250px' }}>{player.guid}</span>
                                <span>
                                    <b>win:</b> {player.win}
                                </span>
                                <span>
                                    <b>loss:</b> {player.loss}
                                </span>
                                <span>
                                    <b>draw:</b> {player.draw}
                                </span>
                                <span>
                                    <b>disconnected:</b> {player.unfinished.disconnected}
                                </span>
                                <span>
                                    <b>connected:</b> {player.unfinished.connected}
                                </span>
                                <span>
                                    <b>abandoned:</b> {player.unfinished.abandoned}
                                </span>
                            </Header>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
    return (
        <Modal
            trigger={<Button>{total}</Button>}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={isOpen}>
            <Modal.Header>Players</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {players.map((player, index) => (
                        <div key={index}>
                            <WhatToRender player={player} />
                        </div>
                    ))}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => setOpen(false)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

// Funzione di raccordo da nuovo codice si-players che tratta scores e counters come Map e questo codice che li usava come array
function transformObjectValuesToArray(obj) {
    const transformed = {}

    Object.entries(obj).forEach(([key, value]) => {
        transformed[key] = Object.values(value)
    })

    return transformed
}

// Aggiunge la proprietà value a counters di si-players
// periodArray è l'array alltime o monthly
const processCounters = periodArray => {
    const res = periodArray.map(item => {
        const value = {
            level: item.level,
            period: item.period,
            draw: 0,
            loss: 0,
            win: 0,
            unfinished: { connected: 0, abandoned: 0, disconnected: 0 },
        }
        value.draw = item.valueObj.reduce((tot, item) => (tot += item.draw), 0)
        value.loss = item.valueObj.reduce((tot, item) => (tot += item.loss), 0)
        value.win = item.valueObj.reduce((tot, item) => (tot += item.win), 0)
    
        value.unfinished.connected = item.valueObj.reduce(
            (tot, item) => (tot += item.unfinished.connected),
            0
        )
        value.unfinished.abandoned = item.valueObj.reduce(
            (tot, item) => (tot += item.unfinished.abandoned),
            0
        )
        value.unfinished.disconnected = item.valueObj.reduce(
            (tot, item) => (tot += item.unfinished.disconnected),
            0
        )
        return value
    })
    // console.log('res', res)
    return res
}

const GroupScoreLevelCounters = ({ shortId, levelInfo, scores, counters, trophies }) => {
    if (!shortId) return 'Missing data'

    // From MAP to Array
    scores = transformObjectValuesToArray(scores)
    counters = transformObjectValuesToArray(counters)

    // console.log('counters', counters)

    // Effettua la reduce per calcolare value
    const countersAlltime = processCounters(counters.alltime)
    const countersMonthly = processCounters(counters.monthly)

    // console.log('scores', scores)
    // console.log('countersAlltime', countersAlltime)
    // console.log('countersMonthly', countersMonthly)
    // console.log('counters', counters)
    // console.log('xxx', counters.monthly.valueObj)
    return (
        <div style={{ paddingBottom: '25px' }}>
            <Header as="h2">ShortId {shortId}</Header>
            <Header as="h2">Scores</Header>
            {/* SCORES */}
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {scores.alltime.length === 0 && scores.monthly.length === 0 && (
                        <div>no scores</div>
                    )}
                    {scores.alltime.map((scoreInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{scoreInfo.level}</Table.Cell>
                            <Table.Cell>{scoreInfo.period}</Table.Cell>
                            <Table.Cell>
                                {/* {scoreInfo.valueObj.reduce((tot, item) => (tot += item.value), 0)} */}
                                <PlayersModal
                                    total={scoreInfo.valueObj.reduce(
                                        (tot, item) => (tot += item.value),
                                        0
                                    )}
                                    players={scoreInfo.valueObj}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    {scores.monthly.map((scoreInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{scoreInfo.level}</Table.Cell>
                            <Table.Cell>{scoreInfo.period}</Table.Cell>
                            <Table.Cell>
                                {/* {scoreInfo.valueObj.reduce((tot, item) => (tot += item.value), 0)} */}
                                <PlayersModal
                                    total={scoreInfo.valueObj.reduce(
                                        (tot, item) => (tot += item.value),
                                        0
                                    )}
                                    players={scoreInfo.valueObj}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Header as="h2">Counters</Header>
            {/* COUNTERS */}
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Draw</Table.HeaderCell>
                        <Table.HeaderCell>Loss</Table.HeaderCell>
                        <Table.HeaderCell>Unfinished Connected</Table.HeaderCell>
                        <Table.HeaderCell>Unfinished Disconnected</Table.HeaderCell>
                        <Table.HeaderCell>Unfinished Abandoned</Table.HeaderCell>
                        <Table.HeaderCell>Win</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {countersAlltime.length === 0 && countersMonthly.length === 0 && (
                        <div>no counters</div>
                    )}
                    {countersAlltime.map((item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{item.level}</Table.Cell>
                            <Table.Cell>{item.period}</Table.Cell>
                            <Table.Cell>{item.draw}</Table.Cell>
                            <Table.Cell>{item.loss}</Table.Cell>
                            <Table.Cell>{item.unfinished.connected}</Table.Cell>
                            <Table.Cell>{item.unfinished.disconnected}</Table.Cell>
                            <Table.Cell>{item.unfinished.abandoned}</Table.Cell>
                            <Table.Cell>{item.win}</Table.Cell>
                            <Table.Cell>
                                <PlayersModal
                                    total={'Details'}
                                    players={
                                        counters.alltime.find(
                                            i => i.level === item.level && i.period === item.period
                                        ).valueObj
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    {countersMonthly.map((item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{item.level}</Table.Cell>
                            <Table.Cell>{item.period}</Table.Cell>
                            <Table.Cell>{item.draw}</Table.Cell>
                            <Table.Cell>{item.loss}</Table.Cell>
                            <Table.Cell>{item.unfinished.connected}</Table.Cell>
                            <Table.Cell>{item.unfinished.disconnected}</Table.Cell>
                            <Table.Cell>{item.unfinished.abandoned}</Table.Cell>
                            <Table.Cell>{item.win}</Table.Cell>
                            <Table.Cell>
                                <PlayersModal
                                    total={'Details'}
                                    players={
                                        counters.monthly.find(
                                            i => i.level === item.level && i.period === item.period
                                        ).valueObj
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

export default GroupScoreLevelCounters
