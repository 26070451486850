import { apiServer } from '../settings';
import { getToken } from './token';
import createLog from '../utils/create-log';

const setEmail = (userGuid, userAuthorization, game, guid, email, done) => {
    // if (!userGuid) return done('missing userGuid')
    // if (!userAuthorization) return done('missing userAuthorization')
    if (!game) return done('missing game');
    if (!guid) return done('missing guid');
    if (!email) return done('missing email');

    const headers = new Headers({
        Authorization: 'Bearer ' + getToken(),
        'Content-Type': 'application/json',
    });

    const options = {
        method: 'PUT',
        headers,
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify({ email }),
    };

    const url = `${apiServer}/user-set-email?game=${game}&guid=${guid}`;

    fetch(url, options)
        .then(response => {
            return response.json();
        })
        .then(response => {
            if (response.success === false) return done(response.err);
            if (response.success === true) return done();
            if (!response.success) return done('response without success true|false');
        })
        .catch(error => {
            console.error('Error:', error);
            done(error.message);
        });
};

export default setEmail;
