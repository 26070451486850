import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import 'semantic-ui-css/semantic.min.css'

import Home from './Home'
import Login from './Login'
import { useAuthorization } from './Contexts/AuthorizationContext'

const App2 = () => {
    const { user } = useAuthorization()

    let isDev
    isDev = process.env.NODE_ENV === 'development'

    return (
        <Router>
            <Switch>
                <Route path="/login">{user || isDev ? <Redirect to="/" /> : <Login />}</Route>
                <Route path="/">{user || isDev ? <Home /> : <Redirect to="/login" />}</Route>
            </Switch>

            {/* <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
           
            {user || isDev ? <Redirect to={{ pathname: '/' }} /> : <Redirect to={{ pathname: '/login' }} />} */}
        </Router>
    )
}

export default App2
