import React, { useState } from 'react'
import 'semantic-ui-css/semantic.min.css'

import { Context } from './Context'
import { BanStatsProvider } from './Contexts/BanStatsContext'
import { BannedUsersProvider } from './Contexts/BannedUsersContext'
import { AuthorizationProvider } from './Contexts/AuthorizationContext'
import App2 from './App2'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'

const App = () => {
    // Per production sempre impostare scopapiu come gioco allo startup (per autenticazione)
    const [game, setGame] = useState(IS_PRODUCTION ? 'scopapiu' : 'scopapiu') // 'solitariopiu-klondike'
    const [group, setGroup] = useState(null)
    const [groups, setGroups] = useState([])
    const [player, setPlayer] = useState(null)
    const [players, setPlayers] = useState([])

    // const [mainTabActiveIndex, setMainTabActiveIndex] = useState(0)

    return (
        // <Context.Provider
        //     value={{
        //         game: game,
        //         setGame: game => {
        //             setGame(game)
        //         },
        //         group: group,
        //         setGroup: group => {
        //             setGroup(group)
        //         },
        //         groups: groups,
        //         setGroups: groups => {
        //             setGroups(groups)
        //         },
        //         player: player,
        //         setPlayer: player => {
        //             setPlayer(player)
        //         },
        //         players: players,
        //         setPlayers: players => {
        //             setPlayers(players)
        //         },
        //         // mainTabActiveIndex: mainTabActiveIndex,
        //         // setMainTabActiveIndex: index => {
        //         //     setMainTabActiveIndex(index)
        //         // }
        //     }}>
        //     <AuthorizationProvider>
        //         <BannedUsersProvider>
        //             <BanStatsProvider>
        //                 <App2 />
        //             </BanStatsProvider>
        //         </BannedUsersProvider>
        //     </AuthorizationProvider>
        // </Context.Provider>
        <Context.Provider
        value={{
            game,
            setGame,
            group,
            setGroup,
            groups,
            setGroups,
            player,
            setPlayer,
            players,
            setPlayers,
        }}>
        <AuthorizationProvider>
            <BannedUsersProvider>
                <BanStatsProvider>
                    <App2 />
                </BanStatsProvider>
            </BannedUsersProvider>
        </AuthorizationProvider>
    </Context.Provider>
    
    )
}

export default App
